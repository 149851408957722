import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

import { Container, H2 } from '../../util/standard'
import { MOBILE_BREAKPOINT, IPAD_BREAKPOINT, TABLET_BREAKPOINT, IPAD_PRO_BREAKPOINT, colors, SMALLER_MOBILE } from '../../util/constants'
import { SanityProgramBlock } from '../../../graphql-types'

import ProgramBlock from '../shared/sub/ProgramBlock'

interface Props {
  columnLayout?: boolean;
  headerHidden?: boolean;
  programs?: Array<SanityProgramBlock> | null | undefined;
}


const OurPrograms = styled.div`

`

const HeaderContainer = styled.div`
width: 80%;
margin: 20px auto;
display: grid;
grid-template-columns: auto 1fr;
grid-gap: 22px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px auto 20px auto;
  }

@media only screen and (max-width: ${SMALLER_MOBILE}px) {
    display: block;
}
`

const StyledH2 = styled(H2)`
width: 100%;
margin: 0 auto;
color: black;
display: inline-block;


`

const ProgramLink = styled.a`
  width: max-content;
  display: inline-grid;
  align-items: center;
  text-decoration: underline;
  color: ${colors.green};
  &:hover {
    color: ${colors.blue};
    text-decoration: underline;

  }
`

// STACKED PROGRAMS LAYOUT

const StackedProgramsWrapper = styled(Container)`
  width: 80%;
  margin: 0 auto;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:20px;

  margin:0 auto 100px auto;
  
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0px auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin:0 auto 40px auto;
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    display: block;
    grid-gap:0px;
  }
`

const StackedContainer = styled.div`
  margin: 0 auto;
  display: inline-grid;
  grid-gap: 20px;
  // height: 80vh;
  height: 800px;
  // @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
  // height: 50vh;
    
  // }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    height: 100%;
    grid-gap:0px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%; 
    width: 100%;
  }
`

// COLUMN PROGRAMS LAYOUT

const ColumnProgramsWrapper = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  padding:0 30px;
  grid-gap: 20px;
  flex-wrap: wrap;
  margin:20px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0px auto;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
  }
`

function StackedProgramsBlock(props: Props) {
  const { programs } = props
  const firstProgram = programs[0].node

  return (
    <StackedProgramsWrapper>
      <ProgramBlock width="49%" height="85%" program={firstProgram} />
      <StackedContainer>
        {programs?.map((program: SanityProgramBlock, index: number) => {
          if ((index > 0) && (index < 3)) {
            return (
              <ProgramBlock program={program.node} key={program.node._id} />
            )
          } else { return }
        })}
      </StackedContainer >
    </StackedProgramsWrapper>
  )

}

function ColumnProgramsBlock(props: Props) {
  const { programs } = props

  return (
    <ColumnProgramsWrapper>
      {programs?.map((program: SanityProgramBlock, index: number) => {
        return (
          <ProgramBlock columnLayout program={program.node} key={program.node._id} />
        )
      })}
    </ColumnProgramsWrapper>
  )
}

function OurProgramsBlock(props: Props) {

  const { allSanityPrograms } = useStaticQuery(graphql`
    {
    allSanityPrograms(sort: {fields: _createdAt, order: DESC}) {
      edges {
          node {
            slug {
              current
            }
            programPreview {
              programTitle
              programSubText
              programCategory
              programImage {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                  }
                  title
                }
              }
            }
            _id
            _createdAt
          }    
        }
      }
    }
  `)


  const { programs } = allSanityPrograms.edges
  const { columnLayout, headerHidden } = props

  return (
    <OurPrograms>
      {!headerHidden && <HeaderContainer>
        <StyledH2 fontWeight="bold">Our Programmes</StyledH2>
        <ProgramLink onClick={() => navigate('/programs')}>View all Programmes</ProgramLink>
      </HeaderContainer>}
      <>
        {columnLayout ? <ColumnProgramsBlock programs={allSanityPrograms.edges} /> : <StackedProgramsBlock programs={allSanityPrograms.edges} />}
      </>
    </OurPrograms>
  )
}

export default OurProgramsBlock
