import React, { useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Container, P, H3 } from '../../../util/standard'
import { LAPTOP_BREAKPOINT, IPAD_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT, icons, SMALLER_MOBILE, colors } from '../../../util/constants'
import { SanityProgramBlock } from '../../../../graphql-types'
import { navigate } from 'gatsby'


interface Props {
  program: SanityProgramBlock;
  width?: string;
  height?: string;
  columnLayout?: boolean;
}

// STACKED LAYOUT

const StackedContainer = styled.div<{ width?: string; }>`
  ${({ width }) => width && `width: ${width}`};
  position: relative;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  grid-template-rows: 1fr auto;
  display: grid;

  &:hover{
    cursor:pointer;
  }

  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    display:block;
    margin-bottom:20px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 20px;

  }
`

const StackedContent = styled.div`
padding: 30px 40px 16px 40px;


`

const ImageWrapper = styled.div`
  width: 100%;
  z-index: -9999;
  position: relative;

  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    height: 30vh;
  }
`

const Category = styled(P) <{ hoverEffect: boolean }>`
  color:white;
  background-color: ${colors.green};
  position:absolute;
  bottom: -16px;
  padding: 10px 15px;

${({ hoverEffect }) => hoverEffect && `background-color: ${colors.blue};`};
transition: background-color 0.2s;
`

const StackedTitle = styled(H3)`
font-size: 24px;
color: ${colors.blue};
`

const StackedSubText = styled(P)`
width: 90%;
`
const StackedImg = styled(Img) <{ hoverEffect: boolean }>`
  position: absolute;
  width: 100% !important;
  height: 100%;

  ${({ hoverEffect }) => hoverEffect && `opacity: 0.8;`};
  transition: opacity 0.2s;
`

// COLUMN LAYOUT

const ColumnContainer = styled.div<{ onClick: () => void }>`
height: 700px;
width:100%;
margin: 0 auto;

&:hover{
  cursor:pointer;
}

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 520px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  height: 450px;
  margin-bottom: 30px;
  } 

`

const ColumnImageWrapper = styled.div` 
  height: 100%;
  width: 100%;
  position: relative;
  z-index: -9999;
  display: inline-grid;
    align-content: end;
  `
const ColumnTextWrapper = styled.div<{ hoverEffect: boolean }>`
margin: 10px;
padding: 30px;
${({ hoverEffect }) => hoverEffect && `
background-color: ${colors.white};
`};

transition: background-color 0.2s;

@media (max-width: ${TABLET_BREAKPOINT}px){
  padding:20px;
}
@media (max-width: ${IPAD_BREAKPOINT}px){
  padding:30px;
}
`

const ColumnImg = styled(Img) <{ hoverEffect: boolean }>`
  width: 100%;
  height: 100%;
  z-index: -9999;
  position: absolute !important;
  filter: brightness(0.5);

  ${({ hoverEffect }) => hoverEffect && `filter: brightness(0.8);`};
  transition: filter 0.2s;
`

const ColumnCategory = styled(P) <{ hoverEffect: boolean }>`
margin: 0px;
${({ hoverEffect }) => hoverEffect && `color: ${colors.blue}`};
transition: color 0.2s;
`

const ColumnTitle = styled(H3) <{ hoverEffect: boolean }>`
font-size: 24px;
line-height: 30px;
margin: 8px 0;
font-weight: 700;
position: relative;
display: grid;
grid-template-columns: auto auto;
grid-gap: 20px;
@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  grid-gap: 0px;
}
${({ hoverEffect }) => hoverEffect && `color: ${colors.blue}`};
transition: color 0.2s;
`

const ColumnSubText = styled(P) <{ hoverEffect: boolean }>`
${({ hoverEffect }) => hoverEffect && `color: ${colors.black}`};
transition: color 0.2s;
`

const StackedArrow = styled.img`
  width: 40px;
  float: right;
  margin-left: 20px;
  justify-self: self-end;
  align-self: center;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 35px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`

const Arrow = styled.img`
  width: 50px;
  float: right;
  justify-self: self-end;
  align-self: center;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 35px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`

function ColumnLayout(props: Props) {
  const [hoverEffect, toggleHoverEffect] = useState(false)
  const { program } = props
  const { programTitle, programCategory, programSubText, programImage } = program.programPreview

  return (
    <ColumnContainer onClick={() => navigate(`/programs/${program.slug.current}`)} onMouseEnter={() => toggleHoverEffect(true)} onMouseLeave={() => toggleHoverEffect(false)}>
      <ColumnImageWrapper>
        <ColumnImg fluid={programImage?.asset?.fluid} hoverEffect={hoverEffect} alt={programImage.asset.title ?? 'program-feature-image'} />
        <ColumnTextWrapper hoverEffect={hoverEffect}>
          <ColumnCategory color="white" hoverEffect={hoverEffect}>{programCategory}</ColumnCategory>
          <ColumnTitle color="white" hoverEffect={hoverEffect}>{programTitle} <Arrow src={hoverEffect ? icons.submitArrowBlue : icons.submitArrow} alt='programs-link-arrow-icon' /></ColumnTitle>
          <ColumnSubText color="white" fontFamily="light" hoverEffect={hoverEffect}>{programSubText}</ColumnSubText>
        </ColumnTextWrapper>
      </ColumnImageWrapper>
    </ColumnContainer >
  )

}

function StackedLayout(props: Props) {
  const [hoverEffect, toggleHoverEffect] = useState(false)
  const { program } = props
  const { programTitle, programCategory, programSubText, programImage } = program.programPreview

  return (
    <StackedContainer onClick={() => navigate(`/programs/${program.slug.current}`)} onMouseEnter={() => toggleHoverEffect(true)} onMouseLeave={() => toggleHoverEffect(false)}>
      <ImageWrapper>
        <StackedImg fluid={programImage?.asset?.fluid} hoverEffect={hoverEffect} alt={programImage.asset.title ?? 'program-feature-image'} />
        <Category color="black" hoverEffect={hoverEffect}>{programCategory}<StackedArrow src={icons.submitArrow} alt='programs-link-arrow-icon' /></Category>
      </ImageWrapper>
      <StackedContent>
        <StackedTitle color="black">{programTitle}</StackedTitle>
        <StackedSubText color="black">{programSubText}</StackedSubText>
      </StackedContent>
    </StackedContainer>
  )
}


function ProgramBlock(props: Props) {
  const { program, width, columnLayout, height } = props
  return (
    <>
      { columnLayout ? <ColumnLayout program={program} height={height} />
        : <StackedLayout program={program} height={height} />}
    </>
  )
}

export default ProgramBlock